import {Poppins, Barlow} from 'next/font/google';
import {pxToRem, responsiveFontSizes} from 'src/utils/getFontValue';

// ----------------------------------------------------------------------

const FONT_PRIMARY = Poppins({
  weight: ['400', '500', '600', '700', '800'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
});

const FONT_SECONDARY = Barlow({
  weight: ['500', '600', '700', '800', '900'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
});

const typography = {
  fontFamily: FONT_PRIMARY.style.fontFamily,
  fontWeightSemiBold: 600,
  h1: {
    fontFamily: FONT_SECONDARY.style.fontFamily,
    fontWeight: 700,
    lineHeight: 1.15,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({sm: 52, md: 58, lg: 64}),
  },
  h2: {
    fontFamily: FONT_SECONDARY.style.fontFamily,
    fontWeight: 700,
    lineHeight: 1.15,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({sm: 40, md: 44, lg: 48}),
  },
  h3: {
    fontFamily: FONT_SECONDARY.style.fontFamily,
    fontWeight: 700,
    lineHeight: 1.15,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({sm: 24, md: 28, lg: 30}),
  },
  h4: {
    fontFamily: FONT_SECONDARY.style.fontFamily,
    fontWeight: 700,
    lineHeight: 1.15,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({sm: 20, md: 22, lg: 22}),
  },
  h5: {
    fontFamily: FONT_SECONDARY.style.fontFamily,
    fontWeight: 600,
    lineHeight: 1.15,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({sm: 18, md: 20, lg: 20}),
  },
  h6: {
    fontFamily: FONT_SECONDARY.style.fontFamily,
    fontWeight: 600,
    lineHeight: 1.15,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({sm: 18, md: 18, lg: 18}),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 28 / 16,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 26 / 14,
    fontSize: pxToRem(14),
  },
  subtitle3: {
    fontWeight: 600,
    lineHeight: 24 / 13,
    fontSize: pxToRem(13),
  },
  body0: {
    lineHeight: 32 / 16,
    fontSize: pxToRem(16),
    ...responsiveFontSizes({sm: 17, md: 18}),
  },
  body1: {
    lineHeight: 28 / 16,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 26 / 14,
    fontSize: pxToRem(14),
  },
  body3: {
    lineHeight: 24 / 13,
    fontSize: pxToRem(13),
  },
  caption: {
    lineHeight: 20 / 12,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 600,
    lineHeight: 20 / 12,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 600,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
};

export default typography;
